<template>
  <div class="admin-edit-promotion">
    <promotion-form isAdmin />
  </div>
</template>

<script>
import PromotionForm from "@/components/promotion-form";

export default {
  name: "admin-edit-promotion",
  components: {
    PromotionForm
  }
};
</script>

<style></style>
